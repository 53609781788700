<template>
  <svg :width="size" :height="size" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33366 2.31494C2.41318 2.31494 1.66699 3.06113 1.66699 3.98161V12.3149C1.66699 13.2354 2.41318 13.9816 3.33366 13.9816H9.03063L11.667 11.5649V5.99345C11.667 5.55143 11.4914 5.1275 11.1788 4.81494L10.1729 3.80902L9.16699 2.8031C8.85443 2.49054 8.43051 2.31494 7.98848 2.31494H3.33366ZM3.33366 3.98161L7.50033 3.98161V5.64827V6.48161H8.33366H10.0003V12.3149H3.33366V3.98161ZM6.66699 6.48161V8.14827H4.16699V9.81494H6.66699V11.4816L8.33366 9.81494L9.16699 8.98161L8.33366 8.14827L6.66699 6.48161ZM8.33366 14.8149V17.3149C8.33366 18.2354 9.07985 18.9816 10.0003 18.9816H16.667C17.5875 18.9816 18.3337 18.2354 18.3337 17.3149V10.9935C18.3337 10.5514 18.1581 10.1275 17.8455 9.81494L16.8396 8.80902L15.8337 7.8031C15.5211 7.49054 15.0972 7.31494 14.6551 7.31494H12.7384V8.98161H14.167V10.6483V11.4816H15.0003H16.667V17.3149H10.0003V14.8149H8.33366ZM11.667 13.1483L13.3337 11.4816V13.1483H15.8337V14.8149H13.3337V16.4816L11.667 14.8149L10.8337 13.9816L11.667 13.1483Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
