<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17 3C17 2.44772 16.5523 2 16 2C15.4477 2 15 2.44772 15 3V4H9V3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V4H5C3.34315 4 2 5.34315 2 7V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V7C22 5.34315 20.6569 4 19 4H17V3ZM7 7V6H5C4.44772 6 4 6.44772 4 7V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V7C20 6.44772 19.5523 6 19 6H17V7L19 7V9L5 9V7H7ZM9 7H15V6H9V7ZM15 15C15 14.4477 14.5523 14 14 14H10.4142L10.7071 14.2929C11.0976 14.6834 11.0976 15.3166 10.7071 15.7071C10.3166 16.0976 9.68342 16.0976 9.29289 15.7071L7.29996 13.7142C7.29878 13.713 7.29759 13.7118 7.29641 13.7106C7.11338 13.5294 7 13.2779 7 13C7 12.7221 7.11339 12.4706 7.29643 12.2894C7.2976 12.2882 7.29878 12.287 7.29996 12.2858L9.29289 10.2929C9.68342 9.90237 10.3166 9.90237 10.7071 10.2929C11.0976 10.6834 11.0976 11.3166 10.7071 11.7071L10.4142 12H14C15.6569 12 17 13.3431 17 15C17 16.6569 15.6569 18 14 18H13.7778C13.2255 18 12.7778 17.5523 12.7778 17C12.7778 16.4477 13.2255 16 13.7778 16H14C14.5523 16 15 15.5523 15 15Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
