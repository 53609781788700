<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.86331 8.52714C3.60296 8.78749 3.60296 9.2096 3.86331 9.46995C4.12366 9.7303 4.54577 9.7303 4.80612 9.46995L7.33472 6.94135L7.33472 13.3319C7.33472 13.7001 7.63319 13.9985 8.00138 13.9985C8.36957 13.9985 8.66805 13.7001 8.66805 13.3319L8.66805 6.94135L11.1966 9.46995C11.457 9.73029 11.8791 9.73029 12.1395 9.46995C12.3998 9.2096 12.3998 8.78749 12.1395 8.52714L8.4775 4.86518C8.21456 4.60224 7.78823 4.60222 7.52526 4.86518L3.86331 8.52714Z"
      :fill="fill"
    />
    <path d="M2.66663 2.66602H13.3333" :stroke="fill" stroke-width="1.33333" />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
