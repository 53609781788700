<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect :width="size" :height="size" rx="14" :fill="circleFill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0002 7.11133C14.3684 7.11133 14.6669 7.4098 14.6669 7.77799V13.3335L20.2224 13.3335C20.5906 13.3335 20.8891 13.632 20.8891 14.0002C20.8891 14.3684 20.5906 14.6669 20.2224 14.6669L14.6669 14.6669V20.2224C14.6669 20.5906 14.3684 20.8891 14.0002 20.8891C13.632 20.8891 13.3335 20.5906 13.3335 20.2224V14.6669H7.77799C7.4098 14.6669 7.11133 14.3684 7.11133 14.0002C7.11133 13.632 7.4098 13.3335 7.77799 13.3335H13.3335V7.77799C13.3335 7.4098 13.632 7.11133 14.0002 7.11133Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    circleFill: { type: String, required: false, default: '#F3F3F4' },
    size: { type: Number, required: false, default: 28 },
  },
};
</script>
