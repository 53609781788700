<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="28" rx="14" :fill="circleFill" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.44434 13.9998C8.44434 14.368 8.74281 14.6665 9.111 14.6665L18.8888 14.6665C19.257 14.6665 19.5554 14.368 19.5554 13.9998C19.5554 13.6316 19.257 13.3332 18.8888 13.3332L9.111 13.3332C8.74281 13.3332 8.44434 13.6316 8.44434 13.9998Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1D1E20' },
    circleFill: { type: String, required: false, default: '#F3F3F4' },
    size: { type: Number, required: false, default: 28 },
  },
};
</script>
