<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 12C20 16.4183 16.4183 20 12 20C10.1513 20 8.44904 19.3729 7.09436 18.3199L18.3199 7.09436C19.3729 8.44904 20 10.1513 20 12ZM19.7429 5.67127C21.1536 7.39514 22 9.59873 22 12C22 17.5228 17.5228 22 12 22C9.59873 22 7.39514 21.1536 5.67127 19.7429L3.70711 21.7071L2.29289 20.2929L4.25705 18.3287C2.84637 16.6049 2 14.4013 2 12C2 6.47715 6.47715 2 12 2C14.4013 2 16.6049 2.84637 18.3287 4.25705L20.2929 2.29289L21.7071 3.70711L19.7429 5.67127ZM5.68014 16.9056L16.9056 5.68014C15.551 4.62708 13.8487 4 12 4C7.58172 4 4 7.58172 4 12C4 13.8487 4.62708 15.551 5.68014 16.9056Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
