<template>
  <svg :width="size" :height="size" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.6667 7.99992C14.6667 11.6818 11.6819 14.6666 8.00001 14.6666C4.31811 14.6666 1.33334 11.6818 1.33334 7.99992C1.33334 4.31802 4.31811 1.33325 8.00001 1.33325C11.6819 1.33325 14.6667 4.31802 14.6667 7.99992ZM8.00001 5.33325C7.26031 5.33325 6.66668 5.92881 6.66668 6.65606H5.33334C5.33334 5.1858 6.53057 3.99992 8.00001 3.99992C9.46945 3.99992 10.6667 5.1858 10.6667 6.65606C10.6667 7.89612 9.81512 8.93371 8.66668 9.22837V9.99992H7.33334V8.77887C7.33334 8.32597 7.69442 8.00183 8.08981 7.97594C8.787 7.93028 9.33334 7.35365 9.33334 6.65606C9.33334 5.92881 8.73971 5.33325 8.00001 5.33325ZM8.00001 12.3333C8.46025 12.3333 8.83334 11.9602 8.83334 11.4999C8.83334 11.0397 8.46025 10.6666 8.00001 10.6666C7.53977 10.6666 7.16668 11.0397 7.16668 11.4999C7.16668 11.9602 7.53977 12.3333 8.00001 12.3333Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#9295A5' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
