<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.28752 20.3066C1.897 20.6971 1.897 21.3303 2.28752 21.7208C2.67805 22.1114 3.31121 22.1114 3.70174 21.7208L5.18672 20.2359C7.12809 21.5221 9.76951 21.31 11.48 19.5995L13.0962 17.9833C13.5704 17.509 13.5704 16.7402 13.0962 16.266L12.5406 15.7103L13.9548 14.2961C14.3453 13.9056 14.3453 13.2724 13.9548 12.8819C13.5643 12.4914 12.9311 12.4914 12.5406 12.8819L11.1263 14.2961L9.7121 12.8819L11.1264 11.4676C11.5169 11.077 11.5169 10.4439 11.1264 10.0533C10.7359 9.66281 10.1027 9.66281 9.71219 10.0533L8.29788 11.4676L7.74242 10.9122C7.26821 10.438 6.49937 10.438 6.02516 10.9122L4.40892 12.5284C2.69847 14.2389 2.48633 16.8803 3.77251 18.8216L2.28752 20.3066ZM6.88379 12.882L11.1264 17.1246L10.0658 18.1853C8.8942 19.3569 6.99471 19.3569 5.82313 18.1853C4.65156 17.0137 4.65156 15.1142 5.82313 13.9426L6.88379 12.882Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.7058 2.28899C22.0964 2.67951 22.0964 3.31268 21.7058 3.7032L20.221 5.18805C21.5073 7.12942 21.2952 9.7709 19.5847 11.4814L17.9684 13.0976C17.4942 13.5718 16.7254 13.5718 16.2512 13.0976L10.8974 7.74383C10.4232 7.26963 10.4232 6.50078 10.8974 6.02658L12.5136 4.41033C14.224 2.6999 16.8654 2.48775 18.8067 3.77387L20.2916 2.28899C20.6821 1.89846 21.3153 1.89846 21.7058 2.28899ZM17.1098 11.1278L12.8672 6.8852L13.9278 5.82454C15.0994 4.65297 16.9989 4.65297 18.1705 5.82454C19.342 6.99611 19.342 8.89561 18.1705 10.0672L17.1098 11.1278Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
