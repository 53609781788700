<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6873 2C13.86 2 14 2.14001 14 2.31273V3.13149C14 3.79111 14.4593 4.3565 15.0684 4.60967C15.6776 4.86289 16.39 4.78161 16.8565 4.31511L17.4357 3.73588C17.5578 3.61373 17.7559 3.61373 17.878 3.73588L20.2641 6.122C20.3863 6.24413 20.3863 6.44216 20.2641 6.56429L19.685 7.14347C19.2184 7.60999 19.1372 8.32243 19.3904 8.93165C19.6436 9.54075 20.209 9.99999 20.8686 9.99999H21.6873C21.86 9.99999 22 10.14 22 10.3127V13.6873C22 13.86 21.86 14 21.6873 14H20.8687C20.2091 14 19.6437 14.4593 19.3905 15.0684C19.1373 15.6777 19.2185 16.3901 19.6851 16.8566L20.2641 17.4357C20.3863 17.5578 20.3863 17.7559 20.2641 17.878L17.878 20.2642C17.7559 20.3863 17.5579 20.3863 17.4357 20.2642L16.8567 19.6851C16.3901 19.2186 15.6777 19.1373 15.0684 19.3905C14.4593 19.6437 14 20.2092 14 20.8688V21.6873C14 21.86 13.86 22 13.6873 22H10.3127C10.14 22 10 21.86 10 21.6873V20.8688C10 20.2092 9.54072 19.6437 8.93159 19.3905C8.32234 19.1373 7.60986 19.2186 7.14332 19.6851L6.56428 20.2642C6.44215 20.3863 6.24414 20.3863 6.12202 20.2642L3.73586 17.878C3.61373 17.7559 3.61373 17.5578 3.73586 17.4357L4.31496 16.8566C4.78148 16.3901 4.86276 15.6776 4.60954 15.0684C4.35636 14.4593 3.79093 14 3.13127 14H2.3127C2.14 14 2 13.86 2 13.6873V10.3127C2 10.14 2.14001 9.99999 2.31272 9.99999H3.13141C3.79103 9.99999 4.35643 9.54075 4.60961 8.93165C4.86284 8.32244 4.78157 7.61 4.31506 7.14349L3.73587 6.5643C3.61373 6.44216 3.61373 6.24413 3.73587 6.122L6.12199 3.73588C6.24413 3.61373 6.44216 3.61373 6.56431 3.73588L7.14355 4.31512C7.61004 4.78162 8.32246 4.86289 8.93166 4.60968C9.54075 4.35651 10 3.79111 10 3.13149V2.31273C10 2.14001 10.14 2 10.3127 2H13.6873ZM12 17.9999C15.3137 17.9999 18 15.3136 18 11.9999C18 8.68623 15.3137 5.99994 12 5.99994C8.68631 5.99994 6.00002 8.68623 6.00002 11.9999C6.00002 15.3136 8.68631 17.9999 12 17.9999Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
