const defaults = {
  VUE_APP_METEOR_URL: 'http://127.0.0.1:3000',
  VUE_APP_API_URI: 'http://127.0.0.1:3000',
};

export default {
  ...defaults,
  ...process.env,
  ...window.APP_CONFIG,
};
